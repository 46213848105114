import React, { useState, useRef } from 'react';


function Accordion() {

  const [activeItem, setActiveItem] = useState(null);
  const [isActive, setIsActive] = useState(false);

    const items = [
        {
          id: 1,
          title: `Ho paura di essere truffato o di acquistare un prodotto falso`,
          content: 
          `
          Il mondo dell'<span class="text-default">oro</span> fisico è un settore ancora poco conosciuto in Italia. Rivolgersi a professionisti del settore con licenza di <em class="text-default">Operatore Professionale in Oro</em> rilasciata <em>da Banca d'Italia</em> è fondamentale. <strong class="text-default">Careisgold</strong> offre tutte le garanzie di sicurezza e flessibilità per gestire al meglio il proprio piano di acquisto in <span class="text-default">oro puro fisico</span>. I lingotti provengono da raffinerie facenti parte della <em>“Good Delivery List”</em>, le quali garantiscono elevatissimi standard produttivi e qualitativi.
          `
        },
        {
          id: 2,
          title: `Non ho abbastanza soldi per investire in oro fisico`,
          content: 
          `
          <strong class="text-default">Careisgold</strong> offre una vasta gamma di servizi, con offerte che si adattano a <em>qualsiasi budget</em> e condizione economica. È possibile diversificare in modo flessibile il proprio portafoglio in un'unica soluzione e/o attraverso versamenti ricorrenti a breve, medio e lungo termine, senza la necessità di cifre esorbitanti. Infatti, chiunque può investire in <span class="text-default">oro puro fisico</span> a partire da una somma equivalente al costo di una semplice colazione al giorno. Le soluzioni sono infinite e personalizzabili a seconda di ogni esigenza, per dare la possibilità a chiunque di crearsi la propria <span class="text-default">riserva aurea</span> e garantirsi un futuro sereno.
          ` 
        },
        {
          id: 3,
          title: `E se i soldi mi servissero prima?`,
          content: 
          `
          Nessun problema! La flessibilità è uno dei punti di forza dei nostri prodotti. Puoi, infatti, decidere di interrompere temporaneamente il tuo piano di acquisto mensile fino addirittura a 2 anni. Oppure, puoi decidere di liquidare una certa quantità di lingotti d’oro già accantonati e depositati in caveau, Veloce Monetizzazione 
          Careisgold offre la possibilità di convertire in denaro l’oro depositato in caveau in soli 15-20 giorni.
          `
        },
        {
          id: 4,
          title: `Ho paura che acquistare oro fisico da investimento possa essere rischioso o poco redditizio`,
          content: 
          `
          L'<span class="text-default">oro</span> fisico non è uno strumento finanziario né un prodotto speculativo ad alto rischio, si tratta invece del <em>bene di rifugio per eccellenza.</em> La sua caratteristica principale è la capacità di <em>mantenere il proprio valore nel tempo</em>: con la stessa quantità di <span class="text-default">oro</span> è possibile acquistare lo stesso prodotto oggi come 20, 50 o 100 anni fa. Per fare un esempio, mezzo chilo d’<span class="text-default">oro</span> negli anni '20 valeva circa 360 €, cioè il costo di un'automobile di allora; oggi la stessa quantità d'<span class="text-default">oro</span> vale circa 30.000 €, quindi il costo di un'automobile moderna. Storicamente, l'<span class="text-default">oro</span> ha sempre preservato il suo potere d'acquisto, motivo per cui rappresenta uno strumento eccellente per proteggere i risparmi dalle oscillazioni del mercato, dall'inflazione e dall'instabilità economica.
          `
        },
        {
          id: 5,
          title: `Non voglio avere lingotti d'oro a casa perché temo per la loro sicurezza`,
          content:
          `
          Non preoccuparti, <strong class="text-default">Careisgold</strong> ha pensato anche a questo! La nostra realtà ha stretto un accordo con <em>il Gruppo Battistolli Spa</em>, l'azienda numero uno in Italia per lo stoccaggio dei valori. I nostri clienti godono, quindi, dei migliori servizi per la protezione del proprio <span class="text-default">oro</span> depositato in caveau di massima sicurezza e assicurato per il suo valore al 100%.
          `
        },
        {
          id: 6,
          title: `E se l’azienda Careisgold fallisse?`,
          content:
          `
          Quando si tratta di investire i propri risparmi, siamo sempre stati abituati ad affidarli a terze parti come banche, poste, compagnie assicurative o fondi. Tuttavia, in questo caso, la sicurezza dell'investimento dipende dall'affidabilità e dalla solvibilità dell'azienda prescelta. Invece, scegliendo di investire in <span class="text-default">oro puro fisico</span>, questo rischio viene completamente eliminato in quanto l'<span class="text-default">oro</span> è un <em>bene fisico di cui si diventa proprietari</em> una volta acquistato. In altre parole, detieni l'<span class="text-default">oro</span> tra le tue mani o all'interno di un caveau come <span class="text-default">oro</span> nominale, di tua proprietà esclusiva. In questa fase, <span class="text-default">Careisgold</span> è il banco metalli che offre la possibilità di convertire il proprio denaro in grammi d'<span class="text-default">oro</span>, garantendo la certezza dell'operazione e fornendo tutti i servizi necessari per la gestione e la sicurezza dell’investimento.  
          `

        },
      ];

      const accordionRef = useRef(null);
      
  return (
    <div>
      <div className="accordion mb-5" id="accordionExample" ref={accordionRef}>
        {items.map((item) => (
          <div
            className="accordion-item my-4 my-md-5">
            <h2 className="accordion-header">
              <a
                href="/"
                className={`${item.id === activeItem && isActive === true ? 'custom-accordion-active' : 'custom-accordion'} question-${item.id} text-decoration-none collapsed shadow bg-dark text-light accordion-button`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${item.id}`}
                aria-expanded={item.id === activeItem && isActive === true ? 'true' : 'false'}
                onClick={(event) => {
                  event.stopPropagation();
                  if (item.id === activeItem) {
                    setIsActive(!isActive);
                  } else {
                    setIsActive(true);
                    setActiveItem(item.id);
                  }
                  accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
              >
                <div className={`container question-${item.id}`}>
                  <div className={`row d-flex justify-content-around align-items-center question-${item.id}`}>
                    <div className={`col-1 no-square bg-default question-${item.id}`}>
                    </div>
                    <div className="col-9">
                      <p className={`lh-sm m-0 fs-2 fw-semibol question-${item.id}`}><span className="fw-bold">"</span>{item.title}<span className="fw-bold">"</span></p>
                    </div>
                    <div className="col-1">
                      <i class={`fa-solid fa-chevron-up text-default question-${item.id}`}></i>
                    </div>
                  </div>
                </div>
         
              </a>
            </h2>
            <div
              id={`collapse-${item.id}`}
              className={`${item.id === activeItem && isActive === true ? 'showCustom' : 'hideCustom'} accordion-collapse collapse bg-transparent`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body custom-accordion-body bg-dark text-light text-start">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-9 col-11">
                    <p className="lh-base m-0 fs-4" dangerouslySetInnerHTML={{__html: item.content}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


export default Accordion